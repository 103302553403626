<template>
  <div class="search-box a-fix-search fx-row fx-c-center">
    <div class="select-tap fx-row fx-center" @click="handleChooseCity">
      <div class="p">{{ cfcName }}出发</div>
      <i></i>
    </div>
    <i></i>
    <div class="search-input">
      <!-- <hl-search left-icon='' v-model="value" shape="round" background="#f7f9fc" placeholder="搜索目的地城市" :readonly="true" disabled @click="handleFocus"
        style="padding: 0;font-size:14px;">
        <div slots="left-icon" class="search-bar-icon-0">
          <img class="search-bar-icon-0" src="../../assets/common/fangdajing.png" />
        </div>
      </hl-search> -->
      <div class="search-input-contain" @click="handleFocus">
        <img class="search-bar-icon-0" src="../../assets/common/fangdajing.png" />
        <div class="search-input-contain-placeholder">搜索目的地城市</div>
      </div>
    </div>
    <city-gty-comp ref="cityGtyhRef" type="origin" title="选择出发地" @on-confirm-city="handleConfirmOriginCity" />
  </div>
</template>

<script>
import Vue from "vue";

import { Search } from "huoli-ui";

Vue.use(Search);

import cityGtyComp from "@/components/city/gty";
import { setOriginCity } from "@/service/localData";

export default {
  components: {
    cityGtyComp,
  },
  props: {
    showType: {
      type: String,
      default: "input",
    },
    originCName: {
      type: String,
      default: "input",
    },
  },
  data() {
    return {
      value: "",
      originCityName: "",
    };
  },

  computed: {
    cfcName() {
      return this.originCityName;
    },
  },

  watch: {
    originCName(newVal) {
      this.originCityName = newVal;
    },
  },

  created() {
    this.originCityName = this.originCName;
  },

  methods: {
    handleFocus() {
      if (this.showType === "read") {
        this.$emit("on-city-fource");
      }
    },

    // 打开城市选择
    handleChooseCity() {
      this.$refs.cityGtyhRef.open();
    },
    // 确定城市选择
    handleConfirmOriginCity(cityData) {
      this.originCityName = cityData.cityName;
      setOriginCity({
        cityName: cityData.cityName,
        cityCode: cityData.cityCode,
      });
      this.$emit("on-search-gty", { type: "origin" });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/_vars.scss";
.search-bar-icon-0 {
  width: 14 * $px;
  height: 14 * $px;
  margin-left: 13 * $px;
  margin-right: 9 * $px;
  margin-top: 1 * $px;
}
.search-input-contain {
  display: flex;
  align-items: center;
  &-placeholder {
    font-size: 14 * $px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    color: rgba(52, 56, 75, 0.5);
  }
}
.search-box {
  // border-radius: 20 * $px;
  // overflow: hidden;

  width: calc(100% - 1.8rem);
  height: 36 * $px;
  background: rgba(255, 255, 255, 0.9) !important;
  backdrop-filter: blur(6px);
  border-radius: 18 * $px;
  overflow: hidden;
  border: 1px solid #ffffff;

  & > i {
    width: 2 * $px;
    height: 20 * $px;
    background: rgba(52, 56, 75, 0.1);
  }

  .select-tap {
    // width: 80 * $px;
    height: 36 * $px;
    padding: 0 10 * $px;

    .p {
      font-size: 14 * $px;
      font-weight: 500;
      color: #262626;
      line-height: 20 * $px;
    }

    i {
      display: inline-block;
      width: 8 * $px;
      height: 5 * $px;
      background-color: transparent;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100% 100%;
      // transform: translate3d(0, 1px, 0);
      margin: 0 0 0 7 * $px;
      background-image: url("https://cdn.133.cn/ticket/images/hangban/vue/hltrip/icon/down-arrow@3x.png");
    }
  }
  .search-input {
    // flex: 1;
    // width: 190 * $px;
    border-radius: 20 * $px;
    overflow: hidden;
    ::v-deep .hl-search {
      background-color: unset !important;
      .hl-search__content {
        background-color: unset !important;
      }
    }
  }
}
</style>
