<template>
  <div class="info-box" @click="goToList">
    <div class="img-box">
      <div class="img" :style="`background-image:url('${item.img}');`"></div>
      <div class="discount" v-if="item.discount">{{item.discount}}折商旅特惠</div>
    </div>
    <div class="tag" v-if="imgTag">
      <span>{{ imgTag }}</span>
      <span class="tag-triangle"></span>
    </div>

    <div class="info">
      <div class="title">{{ item.productName }}</div>
      <title-price :item="item" />
    </div>
  </div>
</template>

<script>
// import * as Utils from "@/service/utils";
// import * as Datetime from "@/utils/datetime";
// import * as LocalData from "@/service/localData";
import TitlePrice from "./title-price";
import env from "@/utils/env";
import { CATEGORY } from "@/service/enumerate";

export default {
  components: {
    TitlePrice,
  },
  props: {
    transferData: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      value: "",
    };
  },
  computed: {
    item() {
      return this.transferData;
    },
    imgTag() {
      let _tagArr = [];
      if (this.item.imgTags) {
        this.item.imgTags.forEach((item) => {
          _tagArr.push(item.name);
        });
      }
      return _tagArr.length > 0 ? _tagArr.join(",") : null;
    },
  },
  methods: {
    goToList() {
      const page = {
        url: "/",
        query: {
          analyseSourceEntry: env.hlsource || "",
        },
      };

      switch (this.item.category) {
        case CATEGORY["门票"]:
          page.url = "/scenic-detail";
          page.name = "scenic-detail";
          page.query["scenicSpotId"] = this.item.scenicSpotId;
          break;
        case CATEGORY["跟团游"]:
          page.url = "/tour-group-detail";
          page.name = "tour-group-detail";
          page.query["productId"] = this.item.productId;
          break;
        case CATEGORY["酒景"]:
          page.url = "/hotel-scenic";
          page.name = "hotel-scenic";
          page.query["productId"] = this.item.productId;
          break;
      }

      // this.$router.push(page)
      this.$page.push(page);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/_vars.scss";
.info-box {
  .img-box {
    height: 90 * $px;
    position: relative;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    .img {
      border-radius: 8 * $px;
      display: block;
      width: 100%;
      height: 100%;
      background-color: transparent;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      object-fit: cover;
    }
  }
  .discount {
    width: 84 * $px;
    height: 20 * $px;
    background: linear-gradient(159deg, #ff5e19 11%, #ff9040 86%);
    border-radius: 0px 8 * $px 0px 8 * $px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    bottom: 0;
    font-size: 11 * $px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 400;
    color: #ffffff;
  }
  .tag {
    position: absolute;
    top: -2 * $px;
    left: 4 * $px;
    background: linear-gradient(159deg, #ff5e19 11%, #ff9040 86%);
    border-radius: 3 * $px;
    width: 22 * $px;
    height: 32 * $px;
    text-align: center;
    &-triangle {
      display: inline-block;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 31 * $px;
      border-top: 7 * $px solid #ff9040;
      border-right: 10 * $px solid transparent;
      border-left: 10 * $px solid transparent;
    }
    span:first-child {
      margin-left: -2 * $px;
      width: 120%;
      margin-top: 3 * $px;
      vertical-align: top;
      display: inline-block;
      line-height: 10 * $px;
      color: #ffffff;
      word-break: break-all;
      font-size: 12 * $px;
      transform: scale(0.65);
    }
  }

  .info {
    width: 100%;
    box-sizing: border-box;

    .title {
      margin: 8 * $px 0 4 * $px;
      font-size: 14 * $px;
      font-weight: 500;
      text-align: left;
      color: #262626;
      line-height: 20 * $px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>
