<template>
  <div class="swiper-part">
    <swiper class="swiper" width="100%" :show-indicators="false" :loop="false">
      <swiper-item
        v-for="(item, index) in ads"
        :key="index"
        @click="clickAd(item)"
      >
        <img :src="item.icon" alt="" style="height: 100%; width: 100%" />
      </swiper-item>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperItem } from "huoli-ui";

export default {
  components: {
    Swiper,
    SwiperItem,
  },

  props: {
    ads: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },

  data() {
    return {};
  },

  created() {},

  methods: {
    clickAd(banner) {
      this.$emit("click-ad", banner);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_vars.scss";
.swiper-part {
  .swiper {
    height: 171 * $px;
  }
  .pic {
    width: 375 * $px;
    height: 171 * $px;
  }
  ::v-deep .hl-swiper-item img {
    object-fit: cover;
  }
}
</style>
