<template>
  <div class="live-header" :style="{ paddingTop: isNative ? '44px' : '' }">
    <div class="container fx-row fx-c-center">
      <!--  返回  -->
      <div class="backBtn" @click="goBack">
        <img
          src="https://cdn.133.cn/ticket/images/hangban/vue/hltrip/icon/zuojiantou@3x.png"
          alt=""
        />
      </div>

      <!--  目的地  -->
      <hl-search
        left-icon="https://cdn.133.cn/ticket/images/hangban/vue/hltrip/icon/fangdajing1@3x.png"
        v-model="value"
        shape="round"
        background="#f7f9fc"
        placeholder="你想去哪"
        :readonly="true"
        disabled
        @click="handleFocus"
        style="padding: 0"
      />

      <!--  门票/跟团游/酒店入口  -->
      <div class="category fx-row fx-m-between">
        <div
          class="category-item"
          v-for="(item, index) in categoryDataShow"
          :key="index"
        >
          <img :src="item.icon" alt="" @click="JumpToList(item)" />
        </div>
      </div>

      <!--  回顶部  -->
      <div class="backTop" @click="scrollToTop">
        <div class="up"></div>
        <div class="down">回顶部</div>
      </div>
    </div>
  </div>
</template>

<script>
import env from "@/utils/env";
import nativeApi from "@/utils/nativeApi";
import service from "@/service";
import get from "@/utils/get";
import * as LocalData from "@/service/localData";
import { CATEGORY } from "@/service/enumerate";

export default {
  name: "live-header",
  props: {
    dtype: {
      type: String,
      default: "1",
    },
  },
  data() {
    return {
      value: "",
      isNative: env.isNative,
      categoryData: [],
    };
  },
  computed: {
    categoryDataShow() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.categoryData.splice(0, 3);
    },
  },
  created() {
    service
      .r215037({
        dtype: this.dtype,
        depCity: LocalData.getOriginCity().cityName,
        depCityCode: LocalData.getOriginCity().cityCode,
      })
      .then((r) => get(r, "res.bd.data"))
      .then((data) => {
        this.categoryData = data;
      });
  },
  methods: {
    handleFocus() {
      this.$emit("on-city-fource");
    },
    scrollToTop() {
      document.querySelector(".home").scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    goBack() {
      if (env.isNative) {
        nativeApi.invoke("close").catch(() => {});
      } else {
        // this.$router.back();
        this.$page.back()
      }
    },
    JumpToList(item) {
      const page = {
        url: '/',
        query: {
        }
      }

      switch (item.code) {
        case CATEGORY["门票"]:
          page.url = "/list";
          page.name = "list";
          break;
        case CATEGORY["跟团游"]:
          page.url = "/tour-group";
          page.name = "tour-group";
          break;
        case CATEGORY["酒景"]:
          page.url = "/hotel-scenic-list";
          page.name = "hotel-scenic-list";
          break;
      }

      // this.$router.push(page)
      this.$page.push(page)
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/_vars.scss";
.live-header {
  transition: all ease-out 0.1s;
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  transform: translateY(-100%);
  background-color: #fff;
  z-index: 3;
  height: 44 * $px;
  .container {
    width: 100%;
    margin: 0 15 * $px;
    padding: 6 * $px 0;

    .backBtn {
      padding: 7 * $px 15 * $px 7 * $px 1 * $px;
      img {
        width: 9 * $px;
        height: 18 * $px;
      }
    }

    .hl-search {
      width: 139 * $px;
      height: 32 * $px;
      border-radius: 16 * $px;
      margin-right: 15 * $px;
      .hl-search__content {
        height: 32 * $px;
      }
    }

    .category {
      &-item {
        margin-right: 12 * $px;
        width: 30 * $px;
        height: 30 * $px;
        border-radius: 50%;
        &:nth-child(1) {
          background: linear-gradient(164deg, #0697ff 5%, #5aa3ff 89%);
        }
        &:nth-child(2) {
          background: linear-gradient(159deg, #ff5e19 11%, #ff9040 86%);
        }
        &:nth-child(3) {
          background: linear-gradient(161deg, #6762ee 8%, #9490ff 88%);
        }
        img {
          width: 30 * $px;
          height: 30 * $px;
        }
      }
    }

    .backTop {
      margin-top: -2 * $px;
      .up {
        transform: scale(0.6);
        border-bottom: 20 * $px solid #0078ff;
        border-left: 18 * $px solid transparent;
        border-right: 18 * $px solid transparent;
      }
      .down {
        margin-top: -2 * $px;
        font-size: 12 * $px;
        transform: scale(0.9);
        color: rgba(52, 56, 75, 0.75);
      }
    }
  }
}
</style>
