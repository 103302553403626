<template>
  <div class="iui-price price fx-row fx-m-start fx-c-end">
    <template v-if="item.salePrice">
      <span class="iui-price-a price-a">{{ item.pricePerfixed }}</span>
      <span class="iui-price-b price-b">{{ item.salePrice }}</span>
      <span class="iui-price-c price-c">{{ item.priceSuffix }}</span>
    </template>
    <span v-else class="iui-price-a price-a">暂无价格</span>
  </div>
</template>

<script>
export default {
  name: "title-price.vue",
  props: ["item"],
};
</script>

<style scoped lang="scss">
@import "../../scss/_vars.scss";
.price {
  span {
    line-height: 1;
  }
  &-a {
    color: #ff6814;
    font-size: 10 * $px;
  }

  &-b {
    color: #ff6814;
    font-size: 16 * $px;
  }

  &-c {
    color: #ff6814;
    font-size: 10 * $px;
  }
}
</style>
