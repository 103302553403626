<template>
  <div class="page home app-page-container" @scroll="onScroll">
    <live-header @on-city-fource="handleCitySearchFource" />

    <c-loading :loading="isLoading">
      <div class="content">
        <div class="main fx-col">
          <div class="main-slider" v-if="ads.length > 0">
            <swipe-comp :ads="ads" />
            <!-- 返回按钮 -->
            <img class="toBackImg" @click="goBack" src="https://cdn.133.cn/ticket/images/hangban/vue/hltrip/icon/home-back@2x.png" v-if="!['sinopec', 'wxapp-hbgj', 'cnpc'].includes(hlsourceMy)" />
            <div class="title">{{ isHaoHuimai? '活力天汇景点票务' : '旅行精品' }}</div>
            <!-- 搜索目的地 -->
            <search-destination-comp showType="read" :originCName="originCityName" @on-city-fource="handleCitySearchFource" @on-search-gty="handleConfirmOriginCity" />
          </div>

          <div class="main-body">
            <div class="body-box">
              <!-- 分类 -->
              <category-list id="category" :autoJump="false" :JumpTo="handleCategoryJumpTo"></category-list>

              <!-- 热门景点 -->
              <div class="hot-citys">
                <!-- 标签 -->
                <category-tags v-if="sceneTags && sceneTags.length" :list-data="sceneTags" @tagClick="handleSceneTag" />

                <div v-if="recScenes && recScenes.length > 0" class="pic-box pic-box-2">
                  <div class="pic-row fx-row fx-m-between">
                    <div class="pic-li">
                      <img-titles :transferData="recScenes[0]" />
                    </div>
                    <div class="pic-li">
                      <img-titles :transferData="recScenes[1]" />
                    </div>
                    <div class="pic-li">
                      <img-titles :transferData="recScenes[2]" />
                    </div>
                  </div>
                  <div v-if="recScenes.length > 3" class="pic-row fx-row fx-m-between">
                    <div class="pic-li">
                      <img-titles :transferData="recScenes[3]" />
                    </div>
                    <div class="pic-li">
                      <img-titles :transferData="recScenes[4]" />
                    </div>
                    <div class="pic-li">
                      <img-titles :transferData="recScenes[5]" />
                    </div>
                  </div>
                </div>

                <!--                <div class="get-more fx-row fx-center">-->
                <!--                  <div class="more-msg">查看更多</div>-->
                <!--                  <img-->
                <!--                    class="more-icon"-->
                <!--                    src="https://cdn.133.cn/ticket/images/hangban/vue/hltrip/icon/more-hotel@3x.png"-->
                <!--                  />-->
                <!--                </div>-->
              </div>

              <!-- 热门城市 -->
              <div class="hot-citys">
                <!-- 标签 -->
                <category-tags ref="hotCityTagsRef" :tag-width="'14%'" @tagClick="handleCityTag" :listData="hotCityTags" />

                <div class="pic-box pic-box-1 fx-row fx-wrap" v-if="hotCitys && hotCitys.length > 0">
                  <!-- 城市入口 -->
                  <div v-if="hotCityShow.cityFlag == 1" class="pic-li" @click="goToList(hotCityShow)">
                    <div class="img-box">
                      <img :src="hotCityShow.img" alt="" />
                      <div class="info">
                        <div class="title">
                          <hl-icon name="address" />
                          {{ hotCityShow.productName }}
                        </div>
                        <div class="desc">
                          {{ hotCityShow.description }}
                        </div>
                      </div>
                    </div>
                  </div>

                  <template v-for="(item, index) in hotCityShowList">
                    <div v-if="item.cityFlag != 1" class="pic-li" :key="index">
                      <img-only-title :item="item" />
                    </div>
                  </template>
                </div>
              </div>

              <!--  订单/下次旅行计划  -->
              <div v-if="showActionContainer" class="action-container fx-row fx-m-around">
                <template v-if="showActionContainerNextTrip">
                  <div v-if="!['kssl', 'hlsl'].includes(hlsource)" class="next-travel-bar fx-row fx-c-center" @click="goToNextTravel">
                    <img class="l" src="https://cdn.133.cn/ticket/images/hangban/vue/hltrip/icon/xingli@3x.png" />
                    <div class="t">下次旅行计划</div>
                  </div>
                  <span class="m"></span>
                </template>
                <div class="next-order-bar fx-row fx-c-center" @click="goToOrderList">
                  <img class="l" src="https://cdn.133.cn/ticket/images/hangban/vue/hltrip/icon/order@2x.png" />
                  <div class="t">我的订单</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </c-loading>
    <!-- 目的地弹出组件 -->
    <city-search-comp ref="citySearchRef" @on-confirm-city="handleConfirmCity" />
    <div class="registrationNo" v-if="isHaoHuimai">
      <div class="registrationNo-txt2">Copyright © 2010-2023 深圳市活力天汇科技股份有限公司</div>
      <div style="display: flex; align-items: center;justify-content: center; margin-bottom: 2px;">
        <img src="https://cdn1.133.cn/config/hltrip/image/guohui.png" class="registrationNo-img" />
        <a class="registrationNo-txt" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030502001271">公安备案号:44030502001271</a>
      </div>
      <a class="registrationNo-txt" href="https://beian.miit.gov.cn/">粤ICP备07509268号-18</a>
    </div>
    <div class="bottomWrap"></div>
  </div>
</template>

<script>
// Internal dependence
import env from "@/utils/env";
import nativeApi from "@/utils/nativeApi";
import get from "@/utils/get";
import throttle from "@/utils/throttle";
import * as LocalData from "@/service/localData";
import { CATEGORY } from "@/service/enumerate";

// components
import liveHeader from "@/projects/home/components/live-header";
import imgOnlyTitle from "@/components/img/only-title";
import imgTitles from "@/components/img/titles";
import citySearchComp from "@/components/city/search";
import searchDestinationComp from "@/components/search/destination";
import categoryList from "@/components/category/category-list";
import categoryTags from "@/components/category/category-tags";
import swipeComp from "./components/swipe";
import service from "@/service";

const hlsource = window.huoli && window.huoli.hlsource;
const InitCityName = LocalData.getOriginCity().cityName;
const InitCityCode = LocalData.getOriginCity().cityCode;

export default {
  components: {
    liveHeader,
    swipeComp,
    searchDestinationComp,
    imgOnlyTitle,
    imgTitles,
    citySearchComp,
    categoryList,
    categoryTags,
  },
  data() {
    return {
      hlsource,
      hlsourceMy: env.hlsource,
      // 查询热门景点标签参数
      initQuery: {
        position: 2, // 位置 1 app首页 2 旅游首页当地推荐  3 旅游首页热门推荐 4 门票首页当地推荐
        pageSize: 6, // 当前位置需要的产品数量 旅游首页当地推荐 传6
        city: InitCityName, // 城市名
        cityCode: InitCityCode, // 城市编码
      },
      isLoading: true,
      isNeedRefresh: false, // 是否需要刷新数据。
      originCityName: InitCityName,
      originCityCode: InitCityCode,
      cityListParams: {
        position: 3,
        city: undefined,
        cityCode: undefined,
      }, // 查询热门城市列表参数
      sceneListParams: {
        position: 2,
        pageSize: 6,
        city: undefined,
        cityCode: undefined,
        tag: undefined,
      }, // 查询热门景点列表参数
      ads: [],
      tags: [],
      hotCityTags: [],
      hotCitys: [],
      sceneTags: [],
      recScenes: [],
    };
  },
  computed: {
    isHaoHuimai() {
      return window.location.hostname == "www.haohuimai.com" || window.location.hostname == "haohuimai.com";
    },
    showActionContainer() {
      return env.isNative;
    },
    showActionContainerNextTrip() {
      return env.appName === "hbgj";
    },
    hotCityShow() {
      if (this.hotCitys && this.hotCitys.length) {
        return this.hotCitys[0];
      }
      return {};
    },
    hotCityShowList() {
      // if (this.hotCitys.length > 4) {
      //   return this.hotCitys.slice(1, 4);
      // }
      return this.hotCitys;
    },
  },
  methods: {
    async init() {
      this.getBanner();
      this.getOriginCity();
      this.getLocate();

      await this.initData();
    },

    // 获取banner广告位
    getBanner() {
      return service
        .r215035()
        .then((r) => get(r, "res.bd.data"))
        .then((data) => {
          this.ads = data;
        });
    },

    // 获取出发城市
    getOriginCity() {
      let originCityData = LocalData.getOriginCity();
      this.originCityName = originCityData.cityName;
      this.originCityCode = originCityData.cityCode;
    },

    //获取定位
    getLocate() {
      if (env.isNative) {
        // let res = getPosition()
      } else {
        //
      }
    },

    // 获取热门城市列表数据
    getHotCityList() {
      return service
        .r215036(this.cityListParams)
        .then((r) => get(r, "res.bd.data"))
        .then((data) => {
          this.hotCitys = data.product || [];
        });
    },

    // 获取热门景点列表数据
    getHotSceneList() {
      return service
        .r215036(this.sceneListParams)
        .then((r) => get(r, "res.bd.data"))
        .then((data) => {
          this.recScenes = data.product || [];
        });
    },

    initData() {
      this.isLoading = true;
      let params = Object.assign({}, this.initQuery);
      return Promise.all([
        // 热门景点标签
        service.r215034(params),
        // 热门城市标签
        service.r215038({ position: 3 }),
      ])
        .then((r) => [get(r[0], "res.bd.data"), get(r[1], "res.bd.data")])
        .then(async (datas) => {
          this.sceneTags = datas[0];
          this.hotCityTags = datas[1];

          // 城市传选中的出发城市，有和选中出发地城市一样的热门城市标签优先选择热门
          // 没有就选城市标签接口返回的第一个
          if (datas[1].map((item) => item.cityName).includes(this.originCityName)) {
            this.cityListParams.city = this.originCityName;
            this.cityListParams.cityCode = this.originCityCode;
            this.hotCityTags.forEach((item, index) => {
              if (item.cityName === this.originCityName) {
                this.$nextTick(() => {
                  this.$refs.hotCityTagsRef.activeIndex = index;
                });
              }
            });
          } else {
            this.cityListParams.city = datas[1][0].cityName;
            this.cityListParams.cityCode = datas[1][0].cityCode;
          }
          // 景点标签默认传接口第一个
          this.sceneListParams.tag = this.sceneTags ? this.sceneTags[0] : undefined;
          this.sceneListParams.city = this.originCityName;
          this.sceneListParams.cityCode = this.originCityCode;

          await this.getHotCityList();
          await this.getHotSceneList();
        })
        .catch(() => {
          return null;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    // 点击热门城市标签
    handleCityTag(tagData) {
      if (tagData.cityCode === this.cityListParams.cityCode) return;
      this.cityListParams.city = tagData.cityName;
      this.cityListParams.cityCode = tagData.cityCode;
      this.getHotCityList();
    },

    // 点击热门景点标签
    handleSceneTag(tag) {
      if (tag === this.sceneListParams.tag) return;
      this.sceneListParams.tag = tag;
      this.getHotSceneList();
    },

    handleCitySearchFource() {
      this.$refs.citySearchRef.open();
    },

    //确定起始地选择
    async handleConfirmOriginCity() {
      await this.getOriginCity();
      let params = {
        position: 2, // 位置 1 app首页 2 旅游首页当地推荐  3 旅游首页热门推荐 4 门票首页当地推荐
        pageSize: 6, // 当前位置需要的产品数量 旅游首页当地推荐 传6
        city: this.originCityName, // 城市名
        cityCode: this.originCityCode, // 城市编码
      };
      return Promise.all([
        // 热门景点标签
        service.r215034(params),
        // 热门城市标签
        service.r215038({ position: 3 }),
      ])
        .then((r) => [get(r[0], "res.bd.data"), get(r[1], "res.bd.data")])
        .then(async (datas) => {
          this.sceneTags = datas[0];
          this.hotCityTags = datas[1];

          // 城市传选中的出发城市，有和选中出发地城市一样的热门城市标签优先选择热门
          // 没有就选城市标签接口返回的第一个
          if (datas[1].map((item) => item.cityName).includes(this.originCityName)) {
            this.cityListParams.city = this.originCityName;
            this.cityListParams.cityCode = this.originCityCode;
            this.hotCityTags.forEach((item, index) => {
              if (item.cityName === this.originCityName) {
                this.$nextTick(() => {
                  this.$refs.hotCityTagsRef.activeIndex = index;
                });
              }
            });
          } else {
            this.cityListParams.city = datas[1][0].cityName;
            this.cityListParams.cityCode = datas[1][0].cityCode;
          }
          // 景点标签默认传接口第一个
          this.sceneListParams.tag = this.sceneTags ? this.sceneTags[0] : undefined;
          this.sceneListParams.city = this.originCityName;
          this.sceneListParams.cityCode = this.originCityCode;

          await this.getHotCityList();
          await this.getHotSceneList();
        })
        .catch(() => {
          return null;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    // 确定城市选择
    handleConfirmCity() {
      // this.$router.push({
      //   path: "/list",
      //   query: {
      //     title: "列表",
      //     cityName: cityData.cityName,
      //     cityCode: cityData.cityCode || 0,
      //     searchContent: "",
      //     productType: 0, //  0: 不限
      //   },
      // });
    },

    goToNextTravel() {
      let url = `weixinhbgj://start?type=weex&hlo_module=hbgj&product_module=nexttrip&hlo_path=citylist&naviBarHidden=1`;
      // location.href = url;
      this.$page.push({
        url: url,
        name: "",
        query: {},
      });
    },

    goToOrderList() {
      // location.href = `${process.env.VUE_APP_BASE_URL_HANGBANH5}/vue/hltrip/order/list`;

      this.$page.push({
        url: `${process.env.VUE_APP_BASE_URL_HANGBANH5}/vue/hltrip/order/list`,
        name: "",
        query: {},
      });
      // this.$go({
      //   url: "/hltrip/order/list",
      //   query: {},
      //   name: "hltrip_order_list", // 要使的params可以传参，必须配置name属性，参考router配置和跳转配置。
      //   params: {},
      // });
    },

    updateTitle(title) {
      if (!title) title = "酒店+门票";
      document.title = title;
      nativeApi
        .ready()
        .then(() => nativeApi.invoke("updateTitle", { text: title }))
        .catch((err) => console.log(err));
    },

    // 展示吸顶搜索条
    onScroll: throttle(() => {
      const headBar = document.querySelector(".live-header");
      // const actionContainer = document.querySelector(".action-container");
      const position = document.getElementById("category");
      const { top, height } = position.getBoundingClientRect();
      if (top + height < 0) {
        headBar.style.transform = "translateY(0)";
        // actionContainer.style.transform = "translate(-50%,0)";
      } else {
        headBar.style.transform = "translateY(-100%)";
        // actionContainer.style.transform = "translate(-50%,700%)";
      }
    }, 15),

    goBack() {
      if (env.isNative) {
        nativeApi.invoke("close").catch(() => { });
      } else {
        // this.$router.back();
        this.$page.back();
      }
    },

    goToList(hotCityShow) {
      // console.log(hotCityShow);
      if (hotCityShow.cityFlag == 1) {
        LocalData.setCity({
          cityName: hotCityShow.cityName,
          cityCode: hotCityShow.cityCode,
        });
        // this.$router.push({ path: '/common-list' })
        this.$page.push({
          url: "/common-list",
          name: "common-list",
          query: {},
        });
      }
    },

    handleCategoryJumpTo(item) {
      const page = {
        url: "/",
        query: {},
        name: "",
        params: {},
      };

      if (item.code === CATEGORY["门票"]) {
        page.url = "/list";
        page.name = "list";
      }
      if (item.code === CATEGORY["跟团游"]) {
        page.url = "/tour-group";
        page.name = "tour-group";
      }
      if (item.code === CATEGORY["酒景"]) {
        page.url = "/hotel-scenic-list";
        page.name = "hotel-scenic-list";
      }

      // this.$router.push(page);
      this.$page.push(page);
    },
  },
  // to:当前页面，from:为来自哪个页面
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.name === "hltrip_list") {
        vm.getOriginCity();
      }
    });
  },
  beforeCreate() {
    // 每次进入清除 LocalStorage
    localStorage.removeItem(LocalData.enumerate.targetCityName);
    // document.addEventListener("visibilitychange", () => {
    //   localStorage.removeItem(LocalData.enumerate.targetCityName)
    // })
    if (env.isNative) {
      nativeApi.invoke("updateHeaderRightBtn", {
        action: "hide",
        icon: "",
        text: "",
        data: {},
      });
      nativeApi.invoke("setNavigationBarVisible", { value: "0" });
      nativeApi.invoke("setNavigationBarTintColor", { statusBarStyle: "1" });
    }
  },
  created() {
    // 设置来源，是 H5 还是控制台mg
    let { from, cityName, cityCode } = this.$route.query || {};
    from && LocalData.setPageFrom(from);
    if (cityName) {
      LocalData.setCity({
        cityName,
        cityCode,
      });
    }

    this.init();

    if (env.isNative) {
      // 有上一个 webview 返回当前 webview 时候触发
      nativeApi.on("resume", () => {
        localStorage.removeItem(LocalData.enumerate.targetCityName);
        this.getOriginCity();
      });
    }
  },
  activated() {
    this.updateTitle("酒店+门票");
  },
  // beforeDestroy() {
  //   if (env.isNative) {
  //     nativeApi.invoke("setNavigationBarVisible", { value: "1" });
  //     nativeApi.invoke("setNavigationBarTintColor", { statusBarStyle: "0" });
  //   }
  // },
};
</script>

<style lang="scss" scoped>
@import "@/scss/style";
@import "@/scss/_vars.scss";

.registrationNo {
  width: 375 * $px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &-txt {
    color: #bbb;
    font-size: 12 * $px;
    // margin-bottom: 4 * $px;
  }

  &-txt:hover {
    color: #000;
  }

  &-txt2 {
    color: #bbb;
    font-size: 12 * $px;
    margin-bottom: 4 * $px;

  }

  &-img {
    width: 14 * $px;
    height: 14 * $px;
    margin-bottom: 2 * $px;
    margin-right: 2 * $px;
  }

}

.bottomWrap {
  height: 34px;
}

.app-page-container:before {
  padding-top: 0;
}

.page {
  position: relative;
  height: 100vh;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  box-sizing: border-box;
  background: #f2f4f7;
}

.main {
  &-slider {
    position: relative;
    width: 100%;
    height: 171 * $px;

    .title {
      font-size: 18 * $px;
      font-weight: bold;
      line-height: 28 * $px;
      position: absolute;
      top: 52 * $px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 2;
    }

    .toBackImg {
      width: 28 * $px;
      height: 28 * $px;
      position: absolute;
      top: 52 * $px;
      left: 12 * $px;
      z-index: 2;
    }

    .search-box {
      position: absolute;
      top: 95 * $px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 2;
    }
  }

  &-body {
    position: relative;

    .body-box {
      position: relative;
      left: 0;
      top: -6 * $px;
      width: 100%;
      border-radius: 12 * $px 12 * $px 0 0;
      box-sizing: border-box;
      padding: 20 * $px 16 * $px 130 * $px;
      overflow: hidden;
      min-height: calc(100vh - 171rem / 20);
      background: linear-gradient(180deg, #ffffff 42%, #f2f4f7 100%);

      &>div {
        margin-bottom: 24 * $px;
      }
    }

    .title {
      margin: 24 * $px 0 16 * $px 0;
      font-weight: 500;
      font-size: 16 * $px;
    }

    .pic-box-1 {
      justify-content: space-between;

      .pic-li {
        position: relative;
        width: 49%;
        height: 193 * $px;
        margin-bottom: 8 * $px;

        .img-box {
          overflow: hidden;
          border-radius: 8 * $px;
          background-color: #fff;
          height: 193 * $px;
          position: relative;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          .info {
            position: absolute;
            box-sizing: border-box;
            left: 6 * $px;
            bottom: 6 * $px;
            border-radius: 6 * $px;
            height: 52 * $px;
            width: 156 * $px;
            background: rgba(255, 255, 255, 0.9);
            padding: 6 * $px 8 * $px;

            .title {
              font-size: 15 * $px;
              color: #000;
              margin: 0 0 2 * $px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;

              .hl-icon-address {
                font-size: 12 * $px;
              }
            }

            .desc {
              color: #262626;
              font-size: 12 * $px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .pic-box-2 {
      justify-content: space-between;

      .pic-row {
        margin-bottom: 16 * $px;

        .pic-li {
          position: relative;
          width: 32%;

          :last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    .get-more {
      .more-msg {
        font-size: 12 * $px;
        font-weight: 400;
        color: #999999;
        margin-right: 5 * $px;
        line-height: 12 * $px;
      }

      .more-icon {
        display: block;
        width: 5 * $px;
        height: 9 * $px;
        line-height: 12 * $px;
      }
    }
  }
}

.action-container {
  transition: all ease-out 0.2s;
  bottom: 34 * $px;
  left: 50%;
  position: fixed;
  width: 283 * $px;
  transform: translate(-50%, 0);
  border-radius: 28 * $px;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  text-align: center;
  margin-bottom: 0 !important;

  .m {
    display: inline-block;
    vertical-align: top;
    width: 1 * $px;
    height: 16 * $px;
    background: rgba(52, 56, 75, 0.1);
    margin-top: 20 * $px;
  }

  .next-travel-bar,
  .next-order-bar {
    width: 141 * $px;
    text-align: center;
    display: inline-block;
    vertical-align: top;
    height: 56 * $px;
    box-sizing: border-box;
    padding: 9 * $px 0 6 * $px;

    .l {
      display: block;
      margin: -3 * $px auto 2 * $px;
      width: 26 * $px;
      height: 26 * $px;
    }

    .t {
      font-size: 13 * $px;
      font-weight: 500;
      color: rgba(52, 56, 75, 0.75);
      flex: 1;
      transform: scale(0.9);
    }
  }

  .next-travel-bar {
    bottom: 66 * $px;
  }

  .next-order-bar {
    bottom: 20 * $px;
  }
}
</style>
