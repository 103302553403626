<template>
  <div class="img-box" @click="JumpToDetail(item)">
    <img class="img" :src="item.img" />
    <div class="info">
      <div class="tag">门票</div>
      <div class="title">{{ item.productName }}</div>
      <title-price :item="item" />
    </div>
  </div>
</template>

<script>
import TitlePrice from "./title-price";
import { CATEGORY } from "@/service/enumerate";
import env from "@/utils/env";

export default {
  components: {
    TitlePrice,
  },
  props: {
    item: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },

  data() {
    return {
      value: "",
    };
  },
  methods: {
    JumpToDetail(item) {
      const page = {
        url: "/",
        query: {
          analyseSourceEntry: env.hlsource || '',
        },
      };

      switch (item.category) {
        case CATEGORY["门票"]:
          page.url = '/scenic-detail'
          page.name = 'scenic-detail'
          page.query['scenicSpotId'] = item.scenicSpotId
          break;
        case CATEGORY["跟团游"]:
          page.url = '/tour-group-detail'
          page.name = 'tour-group-detail'
          page.query['productId'] = item.productId
          break;
        case CATEGORY["酒景"]:
          page.url = '/hotel-scenic'
          page.name = 'hotel-scenic'
          page.query['productId'] = item.productId
          break;
      }

      // this.$router.push(page)
      this.$page.push(page)
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/_vars.scss";
.img-box {
  overflow: hidden;
  border-radius: 8 * $px;
  background-color: #fff;

  .img {
    display: block;
    overflow: hidden;
    width: 100%;
    height: 132 * $px;
    background-color: #aaa;
  }

  .info {
    position: relative;
    width: 100%;
    height: 61 * $px;
    padding: 8 * $px;
    box-sizing: border-box;

    .tag {
      position: absolute;
      top: -8 * $px;
      left: 8 * $px;
      padding: 1 * $px 4 * $px;
      background: linear-gradient(159deg, #ff5e19 11%, #ff9040 86%);
      border-radius: 3 * $px;
      transform: translateY(-100%);
      font-size: 11 * $px;
      color: #fff;
    }

    .title {
      font-size: 14 * $px;
      font-weight: 500;
      text-align: left;
      color: #262626;
      line-height: 22 * $px;
      margin-bottom: 4 * $px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
