<template>
  <div class="category">
    <ul class="category-tags">
      <li
        :class="['category-tags-item', activeIndex === index ? 'active' : '']"
        v-for="(item, index) in listData"
        :key="index"
        @click="handleClick(item, index)"
      >
        {{ item.title || item.cityName || item }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "category-tags",
  props: {
    tagWidth: {
      type: String,
    },
    listData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      activeIndex: 0,
    };
  },
  methods: {
    handleClick(item, index) {
      this.activeIndex = index;
      this.$emit("tagClick", item);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/_vars.scss";

.category {
  width: 100%;
  margin-bottom: 16 * $px;
  &-tags {
    overflow-x: scroll;
    white-space: nowrap;
    overflow-y:hidden;
    &-item.active {
      color: #0078ff;
      background-color: #ebf5ff;
      font-weight: 500;
    }
    &-item {
      margin-right: 8 * $px;
      color: #34384b;
      border-radius: 16 * $px;
      background-color: #f7f9fc;
      font-size: 13 * $px;
      padding: 0 12 * $px;
      line-height: 26 * $px;
      display: inline-block;
      text-align: center;
      transition: all ease-out 0.2s;
      &-title {
        font-size: 12 * $px;
      }
    }
  }
  &-tags::-webkit-scrollbar { width: 0 !important }
}
</style>
